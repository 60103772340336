import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import {BASE_DOMAIN} from "@/api/backend/Repository";
import statusCodesModule from "@/store/statusCodes"; // Importez le module statusCodes ici

Vue.use(Vuex);

function joinPaths(...paths) {
    return paths
        .join('/')
        .replace(/\/+/g, '/')
        .replace(/^(.+):\//, '$1://')
        .replace(/\/(\?|&|#[^!])/g, '$1')
        .replace(/\?/g, '&')
        .replace('&', '?');
}


export default new Vuex.Store({
    state: {
        user: null,
        token: null,
        authenticated: false,

        // Current location
        currentLocation: {
            longitude: 133.981435598584305,
            latitude: -6.864919528121688,
        },

        // Provinces
        provinces: null,
    },
    mutations: {
        authenticate(state, token) {
            // Authenticate: Store auth token
            state.authenticated = true;
            state.token = token;
            localStorage.setItem("authToken", token);
            console.log("Global store: Authenticated");
        },
        updateProfile(state, profile) {
            /* Update profile state, store user information */
            state.user = profile;
        },
        logout(state) {
            // Logging out: Set all auth information to null
            state.user = null;
            state.token = null;
            state.authenticated = false;
            localStorage.setItem("authToken", "");
        },
        saveProvinceInfo(state, data) {
            // Save all the provice data from api call
            state.provinces = data;
        },
    },
    actions: {
        async login(context, token) {
            // API call to get user token and information
            try {
                let backend_domain = process.env.VUE_APP_BACKEND_DOMAIN
                let url_account_verify = joinPaths(backend_domain, 'account/verify')
                const response = await axios.get(
                    url_account_verify,
                    {
                        headers: {
                            Authorization: `Token ${token}`,
                        },
                    }
                );

                if (response.status === 200) {
                    context.commit("authenticate", token);
                    context.commit("updateProfile", response.data);
                }
                return true;
            } catch (e) {
                console.log("Error login in: ", e.response);
                context.commit("logout");
                return false;
            }
        },
    },
    getters: {
        isAuthenticated: (state) => {
            return state.authenticated;
        },
        district: (state) => (id) => {
            /* Return district object by given id */
            return state.provinces
                ? state.provinces.find((dis) => dis.id === id)
                : {};
        },
        isAdmin: (state) => {
            /* Return if user is admin */
            return state.user ? state.user.isAdmin : false;
        },
        isStaff: (state) => {
            /* Return if user is a staff member */
            return state.user ? state.user.isStaff : false;
        },
        email: (state) => {
            /* Return current user's email */
            return state.user ? state.user.email : "email";
        },
    },
    modules: {
            statusCodes : statusCodesModule, // Ajoutez le module statusCodes ici
    },
});
