import Repository from "./Repository";

const resource = "/account";

export async function signInUser(email, password) {
  try {
    const formInfo = {
      email: email,
      password: password,
    };

    const response = await Repository.post(`${resource}/login`, formInfo);
    return response.data.auth_token;

  } catch (e) {
      console.log("Error logging in : ", e);
      throw e;
  }
}

export async function registerUser(email, password) {
  try {
    const registerInfo = {
      email: email,
      password: password,
    };

    const response = await Repository.post(`${resource}/register`, registerInfo);

    if (response.status === 200) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    console.log("Error registering", e);
    throw e;
  }
}

export async function createDriverAccount(driver, verifyToken) {
  try {
    const response = await Repository.post(`${resource}/driver/add`, driver, {
      headers: {
        "Authorization": `Token ${verifyToken}`,
      },
    });

    return response;
  } catch (e) {
    console.log("Error creating driver account", e);
    throw e;
  }
}


