// store/modules/statusCodes.js
import BASE_DOMAIN from "@/api/backend/Repository";
import axios from "axios";

function joinPaths(...paths) {
    return paths
        .join('/')
        .replace(/\/+/g, '/')
        .replace(/^(.+):\//, '$1://')
        .replace(/\/(\?|&|#[^!])/g, '$1')
        .replace(/\?/g, '&')
        .replace('&', '?');
}
export default {
    state: {
        statusCode: {},
    },
    mutations: {
        SET_STATUS_CODES(state, codes) {
            console.log("Setting Status Codes:", codes);
            state.statusCode = codes;
        },
    },
    actions: {
        async fetchStatusCodes({commit}) {
            try {
                let backend_domain = process.env.VUE_APP_BACKEND_DOMAIN
                let url_status_code = joinPaths(backend_domain, "/order/status_code_api")
                console.log('url_status_code : ' + url_status_code)
                const response = await axios.get(
                    url_status_code
                );

                if (response.status === 200) {
                    console.log("Fetched Status Codes:", response.data);
                    const statusCodes = Object.keys(response.data).reduce((result, key) => {
                        result[parseInt(key)] = response.data[key].toLowerCase();
                        return result;
                    }, {});
                    commit("SET_STATUS_CODES", statusCodes);
                }
                return true;
            } catch (e) {
                console.log("Error for fetch status codes from backend: ", e.response);
                return false;
            }
        },
    },
    getters: {
        allStatusCodes: (state) => {
            return state.statusCode;
        },
    },
};
