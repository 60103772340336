<template>
    <div>
        <h1>Please try again later</h1>
        <p>
            Our server is down at the momment due to maintanance or some unaware
            error! We'll be back soon
        </p>
    </div>
</template>

<script>
export default {
    name: "Internal",
};
</script>

<style lang="scss" scoped></style>
