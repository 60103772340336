<template>
<AdvancedChat></AdvancedChat>
</template>

<script>

import AdvancedChat from "@/components/chat/vue-advanced-chat/AdvancedChat.vue";

export default {
  components: {AdvancedChat}
}
</script>

<style lang="scss" scoped>
.wrapper {
    .map-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 100px;
    }

    .header {
        font-size: 2rem;
        font-weight: bold;
        position: relative;

        span {
            color: var(--primary-color);
        }

        p {
            font-size: 1.2rem;
            margin: 10px 0;
        }

        .icon {
            color: var(--primary-color);
            margin: 0 1rem;
        }

        button {
            position: absolute;
            right: 0;
        }
    }

    table {
        width: 100%;
        border-collapse: collapse;
        font-size: 1.2em;
        text-align: center;
        min-width: 400px;
        margin: 2rem 0;
        border-radius: 5px 5px 0 0;
        overflow: hidden;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
        position: relative;

        thead tr {
            background-color: var(--primary-color);
            color: #ffffff;
            text-align: left;
            font-weight: bold;
            text-align: center;
        }

        th,
        td {
            padding: 12px 15px;
        }

        tbody {
            tr {
                border-bottom: 1px solid #dddddd;
                font-weight: 600;

                &:hover {
                    color: var(--primary-color);
                    background-color: #f3f3f3;
                }

                &:last-of-type {
                    border-bottom: 2px solid var(--primary-color);
                }

                i {
                    padding: 5px;
                    font-size: 1.3rem;
                    cursor: pointer;
                    margin-right: 10px;
                    color: var(--secondary-color);

                    transition: 0.2s ease all;

                    &.fa-minus-circle {
                        color: rgb(247, 63, 63);
                    }

                    &:hover {
                        color: var(--primary-color);
                    }
                }
            }
        }
    }

    form {
        margin: 1rem 0;
        padding: 1rem;
        border-bottom: 3px solid var(--primary-color);

        .inputs {
            display: flex;
            gap: 2rem;
            align-items: center;
            font-size: 1.2rem;
            margin: 1rem 0;

            label {
                font-weight: 500;
            }

            input {
                padding: 4px 12px;
                font-size: 1.2rem;
                font-weight: 600;
                line-height: 1.2rem;
                outline: none;
                border: 2px solid var(--secondary-color) !important;

                &:focus {
                    border: 2px solid var(--primary-color) !important;
                    outline: none;
                }
            }
        }

        .error {
            color: red;
            font-weight: 600;
            font-style: italic;

            i {
                margin-right: 5px;
                margin-bottom: 10px;
            }
        }

        .buttons-container {
            display: flex;
            gap: 2rem;

            .close {
                background: rgb(248, 62, 62);
            }
        }
    }
}
</style>
