// All state of an order status
// export const statusOptions = [
//     {
//         id: 1,
//         name: "Processing",
//     },
//     {
//         id: 2,
//         name: "Delivering",
//     },
//     {
//         id: 3,
//         name: "Delivered",
//     },
//     {
//         id: 4,
//         name: "Failed",
//     },
//     {
//         id: 5,
//         name: "Delayed",
//     },
// ];

// All kind of payment methods when user place an order
export const paymentOptions = [
    {
        id: 1,
        name: "Paiement par l'expéditeur",
    },
    {
        id: 2,
        name: "Paiement par le destinataire",
    },
];
