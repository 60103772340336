import axios from "axios";
import Repository from "@/api/backend/Repository";

// const getAllDistricts = async () => {
//     /*
//         Provinces Open API
//         Get all districts of VietNam
//     */
//     try {
//         const url = `https://provinces.open-api.vn/api/?depth=2`;
//         const response = await axios.get(url);
//
//         let provicesData = [];
//         let canthoDistrict = [];
//
//         // Restructure response api data
//         response.data.forEach((provinceData) => {
//             provinceData.districts.forEach((districtData) => {
//                 const district = {
//                     name: `${districtData.name}, ${provinceData.name}`,
//                     id: districtData.code,
//                     provinceCode: provinceData.code,
//                     cityName: provinceData.name,
//                 };
//
//                 if (provinceData.code !== 92) provicesData.push(district);
//                 else canthoDistrict.push(district);
//             });
//         });
//
//         provicesData = [...canthoDistrict, ...provicesData];
//         return provicesData;
//     } catch (e) {
//         console.log("API: Error get province info", e);
//         return {};
//     }
// };

// const getWardList = async (districtId) => {
//     /*  Get list of wards from given district id */
//     try {
//         const url = `https://provinces.open-api.vn/api/d/${districtId}/?depth=2`;
//         const response = await axios.get(url);
//
//         let wards = [];
//         response.data.wards.forEach((ward) => {
//             const wardObj = {
//                 name: ward.name,
//                 id: ward.code,
//             };
//             wards.push(wardObj);
//         });
//         return wards;
//     } catch (err) {
//         console.log(`Error getting subdistrict`, err);
//         return [];
//     }
// };
const useLocalToken = () => {
    /* Use local token to authorized */
    Repository.interceptors.request.use((config) => {
        const token = localStorage.getItem("authToken");
        config.headers.Authorization = token ? `Token ${token}` : "";
        return config;
    });
};

const getWardList = async (districtId) => {
    /*  Get list of wards from given district id */
    try {
        useLocalToken();
        console.log("DistrictId getWardList : " + districtId)
        const response = await Repository.get('sendit/get_other_districts_in_same_city', {
            params : {
            "districtId" : districtId
        }
        });
        return response.data
    } catch (err) {
        console.log(`Error getting subdistrict`, err);
        return [];
    }
};

// const getWard = async (wardCode) => {
//     /* Get a ward's info that correspond with given wardCode */
//     try {
//         const response = await axios.get(
//             `https://provinces.open-api.vn/api/w/${wardCode}`
//         );
//
//         return response.data;
//     } catch (e) {
//         if (e.response.status === 404) {
//             console.log("Invalid ward's code");
//         } else console.log("Error getting wards", e.response);
//         return {};
//     }
// };


const getWard = async (wardCode) => {
    /* Get a ward's info that correspond with given wardCode */
    try {
        useLocalToken();
        const response = await Repository.get('/sendit/get_ward_by_id',
        {
            params: {
                wardID: wardCode
            }
        });
        return response.data

    } catch (e) {
        if (e.response.status === 404) {
            console.log("Invalid ward's code");
        } else console.log("Error getting wards", e.response);
        return {};
    }
};


const getAllDistricts = async () => {
    try {
        useLocalToken();
        const response = await Repository.get('/sendit/get_cities');
        console.log(response.status, response.data)
        return response.data

    } catch (e) {
        console.log("API: Error get city info", e);
        return [];
    }
};

const getDistrictsByCityName = async (cityName) => {
    try {
        useLocalToken();
        const response = await Repository.get(url, {
            params: {
                city: cityName
            }
        });

        let districtsData = [];
        response.data.forEach((districtData) => {
            const district = {
                name: districtData.name,
                id: districtData.id,
                cityName: districtData.city,
                arabicName: districtData.arabic_name,
            };
            districtsData.push(district);
        });

        return districtsData;
    } catch (e) {
        console.log("API: Error get district info", e);
        return [];
    }
};


// export default { getAllCities, getDistrictsByCityName};
export default { getWard, getAllDistricts, getWardList};
