var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"card__info"},[_c('div',{staticClass:"ribbons color",class:_vm.role},[_vm._v(_vm._s(_vm.role))]),_c('div',{staticClass:"header"},[_c('p',{staticClass:"header__name"},[_vm._v(" "+_vm._s(_vm.profile.fullName || "Aucune information")+" ")]),(!_vm.profile.consignee)?_c('div',{staticClass:"role color",class:_vm.role},[_vm._v(" "+_vm._s(_vm.profile.email)+" ")]):_c('div',{staticClass:"role color"},[_vm._v(" Consignee of "),_c('router-link',{staticClass:"normal-link",attrs:{"to":{
                            name: 'OrderDetail',
                            params: { id: _vm.profile.orderId },
                        }}},[_vm._v(" Order "+_vm._s(_vm.profile.orderId)+" ")])],1)]),_c('div',{staticClass:"content"},[(_vm.profile.consignee)?_c('div',{staticClass:"content__btn"},[_c('router-link',{staticClass:"btn small",attrs:{"to":{
                            name: 'OrderDetail',
                            params: { id: _vm.profile.orderId },
                        }}},[_c('i',{staticClass:"fas fa-box-open"}),_vm._v("Orders "+_vm._s(_vm.profile.orderId)+" ")])],1):_c('div',{staticClass:"content__btn"},[(_vm.profile.isStaff & !_vm.profile.isAdmin)?_c('router-link',{staticClass:"btn small",attrs:{"to":{
                            name: 'Profile',
                            params: { email: _vm.profile.email },
                            query: { tab: 1 },
                        }}},[_c('i',{staticClass:"fas fa-plus-circle"}),_vm._v("CMD ")]):_vm._e(),_c('router-link',{staticClass:"btn small",attrs:{"to":{
                            name: 'Profile',
                            params: { email: _vm.profile.email },
                        }}},[_c('i',{staticClass:"fas fa-user-circle"}),_vm._v("Profil ")])],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card__profile"},[_c('img',{attrs:{"src":require("../../assets/test/user-icon.jpg"),"alt":""}})])
}]

export { render, staticRenderFns }